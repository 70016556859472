<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <!-- <h6 class="h2 d-inline-block mb-0">Projekte</h6> -->
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-split-button class="pr-1 ml-2" type="primary" size="sm" :text="$t('message.change-status')"
            v-loading="changingStatus" 
            v-if="(currentUserRights.includes('bhb.status.can-modify-freigabe') || 
                  currentUserRights.includes('bhb.status.can-modify-korrektur') || 
                  currentUserRights.includes('bhb.status.can-modify-offen') || 
                  currentUserRights.includes('bhb.status.can-modify-ablehnen')) && 
                  (selectedIMPositions.length > 0 && project && project.state !== 'ARCHIVED')" >
                <!-- <a class="dropdown-item" >Komplettes Dokument</a> -->
              <a class="dropdown-item" @click="changeStatus('FREIGEGEBEN')" v-if="currentUserRights.includes('bhb.status.can-modify-freigabe')">{{$t('message.release')}}</a>
              <a class="dropdown-item" @click="changeStatus('KORREKTUR')" v-if="currentUserRights.includes('bhb.status.can-modify-korrektur')">{{$t('message.correction')}}</a>
              <a class="dropdown-item" @click="changeStatus('OFFEN')" v-if="currentUserRights.includes('bhb.status.can-modify-offen')">{{$t('message.stateOpen')}}</a>
              <a class="dropdown-item" @click="changeStatus('ABGELEHNT')" v-if="currentUserRights.includes('bhb.status.can-modify-ablehnen')">{{ $t('message.reject') }}</a>
              <a class="dropdown-item" @click="changeMounted" v-if="currentUserRights.includes('bhb.status.can-modify-montiert')">{{ $t('message.mounted') }}</a>
              <a class="dropdown-item" @click="changeWirtschaftlicheFreigabe" v-if="currentUserRights.includes('bhb.status.can-modify-wirtschaftliche-freigabe')">{{$t('message.economic_release_and_state_release')}}</a>
          </base-split-button>
          <base-button size="sm" type="primary" @click="showFrontpageModal = true" v-if="currentUserRights.includes('BHB - Startseite_Deckblatt hinzufügen')" :disabled="project && project.state === 'ARCHIVED'">
            {{$t('message.addCoverPage')}}
          </base-button>
          <base-button size="sm" type="primary" @click="openExportModal" v-if="items.length > 0 && currentUserRights.includes('BHB - Startseite_Exportieren')">
            {{ $t('message.export') }}
          </base-button>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <card no-body>
            <template slot="header">
              <div class="row">
                <div class="col-6" >
                    <h3 class="mb-0" v-if="project">{{project.name}}</h3>
                    <p v-if="currentUserRights.includes('BHB - Vollbild_Ansicht mit Preis')">{{$t('message.production_costs_total')}}: {{ totalPrice | currency }} / {{$t('message.price_offer')}}: {{ offerPrice | currency }}</p>
                </div>
                <div class="col-6 text-right">
                   <div class="btn-group btn-group-toggle" data-toggle="buttons">
                      <label class="btn btn-secondary" :class="{ active: cols === 0 }">
                        <input type="radio" id="list"  checked :value="0" v-model="cols"> <i class="fas fa-list"></i>
                      </label>
                      <label class="btn btn-secondary" :class="{ active: cols === 3 }">
                        <input type="radio" id="twoCols" :value="3" v-model="cols"> <i class="fas fa-th-large"></i>
                      </label>
                      <label class="btn btn-secondary" :class="{ active: cols === 6 }">
                        <input type="radio" id="freeCols"  :value="6" v-model="cols"> <i class="fas fa-th"></i>
                      </label>
                    </div>
                </div>
              </div>
            </template>
            <div class="row">
              <div class="col m-3">
                <el-select
                  class="select-primary mr-2"
                  v-model="areaFilterValue" filterable
                  :placeholder="$t('message.select_area')">
                  <el-option class="select-primary ml-1"
                    value="$"
                    :label="$t('message.all_areas')"
                    key="-1"></el-option>
                  <el-option v-for="option in options.filter(o => o.type=='AREA')"
                    :key="option.id"
                    :label="option.name"
                    :value="option.name">
                  </el-option>
                </el-select>
                <el-select
                  class="select-primary mr-2"
                  v-model="colorFilterValue" filterable>
                  <el-option class="select-primary ml-1"
                    value="$"
                    :label="$t('message.all_colors')"
                    key="-1"></el-option>
                  <el-option v-for="option in options.filter(o => o.type=='COLOR')"
                    :key="option.id"
                    :label="option.name"
                    :value="option.name">
                  </el-option>
                </el-select>
                <el-select
                  class="select-primary mr-2"
                  v-model="positionFilterValue" filterable
                  placeholder="Position auswählen">
                  <el-option class="select-primary ml-1"
                    value="$"
                    :label="$t('message.all_positions')"
                    key="-1"></el-option>
                  <el-option v-for="option in options.filter(o => o.type=='BHBPOS')"
                    :key="option.id"
                    :label="option.name"
                    :value="option.name">
                  </el-option>
                </el-select>
                <el-select
                  class="select-primary mr-2"
                  v-model="statusFilterValue"
                  placeholder="Status">
                  <el-option v-for="(option, index) in stateFilterValues"
                    class="select-primary ml-1"
                    :value="index"
                    :label="option"
                    :key="index"
                    :disabled="(index === 4 || index === 6) && !currentUserRights.includes('bhb.status.can-modify-handlung-pl')">
                  </el-option>
                </el-select>
                <el-select
                    class="select-primary mr-2"
                    v-model="mountedFilterValue" filterable
                    :placeholder="$t('message.select_area')">
                  <el-option class="select-primary ml-1"
                    value="$"
                    :label="$t('message.mountedStates.ALLE')"
                    key="-1"></el-option>
                  <el-option class="select-primary ml-1"
                    value="1"
                    :label="$t('message.mounted')"
                    key="1"></el-option>
                  <el-option class="select-primary ml-1"
                    value="0"
                    :label="$t('message.not_mounted')"
                    key="2"></el-option>
                </el-select>
                <el-select collapse-tags class="mr-2 select-primary" v-model="orderFilterValue" :placeholder="$t('data_input.order_label')">
                   <el-option class="select-primary ml-1"
                      value="$"
                      :label="$t('data_input.all_orders_label')"
                      key="-1"></el-option>
                  <el-option
                    v-for="option in orderNumbers"
                    class="select-primary"
                    :value="option"
                    :label="option"
                    :key="option"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="row">
              <div class="col mx-3">
                <base-checkbox v-model="actionPlFilter" class="mr-2" style="display: inline-block;" v-if="currentUserRights.includes('bhb.status.can-modify-handlung-pl')">{{$t('message.actionPL')}}</base-checkbox>
                <base-checkbox v-model="actionNoPlFilter" class="mr-2" style="display: inline-block;" v-if="currentUserRights.includes('bhb.status.can-modify-handlung-pl')">{{$t('message.noActionPL')}}</base-checkbox>
                <base-checkbox v-model="economicRelease" class="mr-2"  style="display: inline-block;">
                  {{ $t('message.economic_release') }}
                </base-checkbox>
                 <base-checkbox v-model="noEconomicRelease" class="mr-2" style="display: inline-block;">
                  {{ $t('message.no_economic_release') }}
                </base-checkbox>
                <base-checkbox v-model="showRejected" class="mr-2" style="display: inline-block;">
                  {{ $t('message.show_rejected') }}
                </base-checkbox>
                <base-checkbox v-model="dvErstellt" class="mr-2" style="display: inline-block;" v-if="currentUserRights.includes('bhb.status.can-modify-dv-erstellt')">
                  {{ $t('message.show_dv_erstellt') }}
                </base-checkbox>
                <base-checkbox v-model="dvNichtErstellt" class="mr-2" style="display: inline-block;" v-if="currentUserRights.includes('bhb.status.can-modify-dv-erstellt')">
                  {{ $t('message.show_dv_nicht_erstellt') }}
                </base-checkbox>
                <base-checkbox v-model="showNichtOk" class="mr-2" style="display: inline-block;" v-if="currentUser.role_id !== 13 && currentUser.role_id !== 14">
                  {{ $t('message.show_nicht_ok') }}
                </base-checkbox>
                <base-checkbox v-model="showHasDebriefing" class="mr-2" style="display: inline-block;" v-if="currentUser.role_id !== 13 && currentUser.role_id !== 14">
                  {{ $t('message.show_has_debriefing') }}
                </base-checkbox>
              </div>
            </div>
            <grid-view :items="items" v-if="cols > 0" :project="project" :docs="docs.position" :columns="cols"></grid-view>
            <list-view :items="items" :project="project" :docs="docs.position" v-else></list-view>
          </card>
          </div>
        </div>
      </div>
      <front-page-modal :show.sync="showFrontpageModal" :project="project" @close="showFrontpageModal=false"></front-page-modal>
      <bhb-export-form :show.sync="exportModal"
      />
    </div>
</template>
<script>
  // Charts

  import { mapGetters, mapActions } from 'vuex'
  import GridView from './GridView'
  import ListView from './ListView'
  import FrontPageModal from './FrontPage'
  import isCustomerAdr from '@/util/customerAdr'
  import BHBExportForm from './BHBExportForm';
  import sleep from '@/util/sleep';

  export default {
    name: 'BHB',
    components: {
      GridView,
      ListView,
      FrontPageModal,
      'bhb-export-form' : BHBExportForm
    },
    computed: {
      ...mapGetters([
        'bhbViewColumns',
        'projects',
        'project',
        'docs',
        'positions',
        'currentProjectAddress',
        'IMPositions',
        'bhbFilterValue',
        'bhbFilterType',
        'bhbStateValues',
        'IMStatusFilterValue',
        'IMAreaFilterValue',
        'IMPositionFilterValue',
        'IMMountedFilterValue',
        'IMEconomicRelease',
        'IMNoEconomicRelease',
        'IMActionPlFilter',
        'IMActionNoPlFilter',
        'IMOrderFilterValue',
        'IMShowRejected',
        'currentUserRights',
        'options',
        'authorisedStateValues',
        'currentUser',
        'selectedIMPositions',
        'offerPositions',
        'roles',
        'ColorFilterValue'
      ]),
      colorFilterValue: {
        get () {
          return this.ColorFilterValue
        },
        set (value) {
          this.$store.commit('ColorFilterValue', value)
        }
      },
      stateFilterValues () {
        const temp = [...new Set(this.authorisedStateValues)]
        temp.unshift('ALLE_FREIGABEN')
        return temp.map(i => this.$t('message.bhbState.' + i))
      },
      statusFilterValue:{
        get () {
          return this.IMStatusFilterValue
        },
        set (val) {
          this.$store.commit('IMStatusFilterValue', val)
        }
      },
      areaFilterValue: {
        get () {
          return this.IMAreaFilterValue
        },
        set (value) {
          this.$store.commit('IMAreaFilterValue', value)
        }
      },
      positionFilterValue: {
        get () {
          return this.IMPositionFilterValue
        },
        set (value) {
          this.$store.commit('IMPositionFilterValue', value)
        }
      },
      mountedFilterValue: {
        get () {
          return this.IMMountedFilterValue
        },
        set (value) {
          this.$store.commit('IMMountedFilterValue', value)
        }
      },
      orderFilterValue: {
        get () {
          return this.IMOrderFilterValue
        },
        set (value) {
          this.$store.commit('IMOrderFilterValue', value)
        }
      },
      economicRelease: {
        get () {
          return this.IMEconomicRelease
        },
        set (value) {
          this.$store.commit('IMEconomicRelease', value)
        }
      },
      noEconomicRelease: {
        get () {
          return this.IMNoEconomicRelease
        },
        set (value) {
          this.$store.commit('IMNoEconomicRelease', value)
        }
      },
      actionPlFilter: {
        get () {
          return this.IMActionPlFilter
        },
        set (value) {
          this.$store.commit('IMActionPlFilter', value)
        }
      },
      actionNoPlFilter: {
        get () {
          return this.IMActionNoPlFilter
        },
        set (value) {
          this.$store.commit('IMActionNoPlFilter', value)
        }
      },
      showRejected: {
        get () {
          return this.IMShowRejected
        },
        set (value) {
          this.$store.commit('IMShowRejected', value)
        }
      },
      cols: {
        get() {
          return this.bhbViewColumns
        },
        set (columns) {
          this.$store.dispatch('setBhbViewColumns', columns)
        }
      },
      items () {
        let result = [...this.IMPositions]
        result = this.statusFilterValue === 0
            ? result
            : result.filter(p => p.state_bhb === this.bhbStateValues[this.statusFilterValue - 1])

        if (this.areaFilterValue !== '$') {
          result = result.filter(i => i.area ? i.area.name.toLowerCase() === this.areaFilterValue.toLowerCase() : false)
        }

        if (this.colorFilterValue !== '$') {
          result = result.filter(i => i.color ? i.color.name === this.colorFilterValue : false)
        }

        if (this.positionFilterValue !== '$') {
          result = result.filter(i => i.bhb_position ? i.bhb_position.name.toLowerCase() === this.positionFilterValue.toLowerCase() : false)
        }

        if (this.mountedFilterValue !== '$') {
          result = result.filter(i => i.mounted == this.mountedFilterValue)
        }

        if (this.actionPlFilter) {
          result = result.filter(i => i.handlung_pl == true)
        }

        if (this.actionNoPlFilter) {
          result = result.filter(i => i.handlung_pl == false)
        }

        if (this.orderFilterValue !== '$') {
          result = result.filter(i => i.order_no == this.orderFilterValue)
        }

        if (!this.showRejected) {
          result = result.filter(p => p.state_bhb !== 'ABGELEHNT')
        }

        if (this.dvErstellt) {
          result = result.filter(p =>{
            return p.dv_erstellt == true
          })
        }

        if (this.dvNichtErstellt) {
          result = result.filter(p =>{
            return p.dv_erstellt != true
          })
        }

        if (this.showNichtOk) {
          result = result.filter(p => p.mounted == true && p.mounted_ok == false)
        }
        if(this.showHasDebriefing){
          result = result.filter(p => p.has_debriefing == true)
        }
        result = result.filter(i => {
          if (this.economicRelease) {
            if (this.noEconomicRelease) {
              return i.rel_business == true || i.rel_business == false
            }
            return i.rel_business == true
          }

          if (this.noEconomicRelease) {
            if (this.economicRelease) {
              return i.rel_business == true || i.rel_business == false
            }
            return i.rel_business == false
          }
        })

        return result.sort((a, b) => {
          return a.tempsort - b.tempsort
        })
      },
      offerItems () {
        let result = this.offerPositions
          .filter(i => i.state_bhb !== 'ABGELEHNT')

        return result
      },
      offerPrice: function() {
        const total = this.offerItems.reduce((val, pos) => {
            const price = isCustomerAdr(this.currentUser, this.project, pos.address)
              ? Number.parseFloat(pos.price_total)
              : 0
            return val + price
          }, 0)

        const percent = this.positions.reduce((val, pos) => {
            let price = isCustomerAdr(this.currentUser, this.project, pos.address)
              ? Number(pos.price_percent)
              : 0;
            if (this.currentProjectAddress !== pos.address.address_id && this.currentProjectAddress !== 0) {
              price = 0;
            }
            return val + price;
          }, 0)
        return total * (1.0 + percent/100.0)
      },
      totalPrice () {
        const total = this.items
          .filter(p => p.state_bhb !== 'ABGELEHNT')
          .map(p => {
            return isCustomerAdr(this.currentUser, this.project, p.address)
              ? Number(p.price_total)
              : 0
          })
          .reduce((a, b) => a + b, 0)
        const percent = this.items
          .filter(p => p.state_bhb !== 'ABGELEHNT')
          .map(p => {
            return isCustomerAdr(this.currentUser, this.project, p.address)
              ? Number(p.price_percent)
              : 0
          })
          .reduce((a, b) => a + b, 0)
        return total * (1.0 + percent/100.0)
      },
      orderNumbers () {
        const items = this.IMPositions
        return [...new Set(items.map(item => item.order_no ? item.order_no : 'Leer'))]
      }
    },
    data() {
      return {
        viewMode: 'twoCols',
        showFrontpageModal: false,
        loadingExport: false,
        statusFilterValue: 0,
        actionPlFilter: false,
        economicRelease: true,
        noEconomicRelease: true,
        changingStatus: false,
        exportModal: false,
        dvErstellt: false,
        dvNichtErstellt: false,
        showNichtOk: false,
        showHasDebriefing: false
      }
    },
    methods: {
      ...mapActions([
        'loadPositionsForPorject',
        'clearFilter',
        'setBhbStateBulk',
        'bulkMountedState',
        'clearSelectedIMPositions',
        'bulkWirtschaftlicheFreigabe',
        'bulkWirtschaftlicheFreigabeAndInhaltlicheFreigabe'
      ]),
      openExportModal() {
        this.exportModal = true;
      },
      async confirm({ callback, confirmMessage, successMessage }) {
        try {
          await this.$confirm(
            confirmMessage,
            this.$t("message.warning"),
            {
              confirmButtonText: this.$t("message.yes"),
              cancelButtonText: this.$t("message.no"),
              type: "warning"
            }
          );
        } catch (error) {
          return this.$message({
            type: "info",
            message: "Canceled"
          });
        }

        this.changingStatus = true;
        await callback();
        await sleep(100);

        this.changingStatus = false;
        if (successMessage) {
          this.$message({ type: "success", message: successMessage });
        }
      },
      hasFunction(func) {
        return this.project.functions.includes(func);
      },
      changeWirtschaftlicheFreigabe () {
        this.bulkWirtschaftlicheFreigabeAndInhaltlicheFreigabe(this.selectedIMPositions)
      },
      changeMounted() {
        this.confirm({
          callback: async () => {
            this.bulkMountedState(this.selectedIMPositions);
            this.changingStatus = false;
            this.clearSelectedIMPositions();
          },
          confirmMessage: this.$t("message.confirm_bulk", { count: this.selectedIMPositions.length, state: this.$t('message.mounted')}),
          successMessage: this.$t("message.status_changed_success")
        })
      },
      changeStatus (state) {
        this.confirm({
          callback: async () => {
            const bhbState = state
            this.setBhbStateBulk({ posIds: this.selectedIMPositions, bhbState });
            this.changingStatus = false
            this.clearSelectedIMPositions()
          },
          confirmMessage: this.$t("message.confirm_bulk", { count: this.selectedIMPositions.length, state: this.$t(`message.bhbState.${state}`)}),
          successMessage: this.$t("message.status_changed_success")
        })
      },
      isCustomerAdr,
    },
    beforeRouteLeave (to, from, next) {
      this.clearFilter()
      next()
    },
    mounted() {
      this.loadPositionsForPorject(this.$route.params.id)
      if (this.bhbFilterType === 'mounted'){
        this.mountedFilterValue = this.bhbFilterValue
      }

      if (this.bhbFilterType === 'state'){
        this.statusFilterValue = this.bhbFilterValue
      }
    }
  };
</script>
<style></style>
